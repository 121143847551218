import { HOTJAR_USER_ID } from "@/constants";

/**
 * Appends the hotjar tracking script to the head of the document.
 */
export const createHotJarTrackingScript = () => {
    if (import.meta.env.VITE_CURRENT_ENV !== "PRODUCTION") return;

    const script = document.createElement("script");
    script.id = "hotjar-base";
    script.type = "text/javascript";
    script.text = `
                        (function (h, o, t, j, a, r) {
                h.hj =
                    h.hj ||
                    function () {
                        (h.hj.q = h.hj.q || []).push(arguments);
                    };
                h._hjSettings = { hjid: ${HOTJAR_USER_ID}, hjsv: 6 };
                a = o.getElementsByTagName("head")[0];
                r = o.createElement("script");
                r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
            })(
                window,
                document,
                "https://static.hotjar.com/c/hotjar-",
                ".js?sv="
            );
            `;

    document.head.appendChild(script);
};
